import React from "react";
import { format } from "date-fns";
import ConfigurationForm from "./ConfigurationForm";

import { SIM_CREDITOR_NONE } from "./smsDevice/SimCreditor";
import { useApi } from "../../../../../api";
import {
  createFieldErrorMessage,
  setFieldErrorsFromResponse
} from "../../../../../utils/formErrors";
import {
  inMostSuitableTimeUnit,
  TIME_UNITS,
  valueAndTimeUnitToSeconds
} from "../../../../../utils/timeUtils";
import {
  DEVICE_CAPABILITIES,
  DEVICE_SMS_GATEWAY_TYPE,
  DEVICE_TYPE,
  IOT_TYPE
} from "../../../../../domain/device";
import { SENSOR_TYPE } from "../../../../../domain/sensor";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";
import { useSmsGateways } from "../../../../../domain/smsGateway";
import { IOT_MAX_TEMP, IOT_MIN_TEMP } from "./reporting/IotTemp";

const ConfigurationContainer = ({ device, onClose }) => {
  const api = useApi();
  const smsGateways = useSmsGateways();
  const { enqueueSnackbar } = useI18nSnackbar();

  const validate = values => {
    const errors = {};

    values.sensors.forEach((sensor, idx) => {
      const sensorErrors = {};

      if (!sensor.isConnected) {
        return;
      }

      if (device.type === DEVICE_TYPE.IOT && !sensor.measuringRange) {
        sensorErrors.measuringRange = createFieldErrorMessage(
          "measuringRange",
          "required"
        );
      }

      if (
        device.capabilities === DEVICE_CAPABILITIES.AMPERE ||
        device.type === DEVICE_TYPE.LX_IOT
      ) {
        if (
          device.capabilities === DEVICE_CAPABILITIES.AMPERE &&
          !sensor.fillHeight &&
          sensor.fillHeight !== 0
        ) {
          sensorErrors.fillHeight = createFieldErrorMessage(
            "fillHeight",
            "required"
          );
        }

        if (
          device.type === DEVICE_TYPE.LX_IOT &&
          !sensor.fillHeightMm &&
          sensor.fillHeightMm !== 0
        ) {
          sensorErrors.fillHeightMm = createFieldErrorMessage(
            "fillHeightMm",
            "required"
          );
        }

        if (!sensor.geometry) {
          sensorErrors.geometry = createFieldErrorMessage(
            "geometry",
            "required"
          );
        }

        if (!sensor.contentDensity) {
          sensorErrors.contentDensity = createFieldErrorMessage(
            "contentDensity",
            "required"
          );
        }
      }

      if (sensor.type === SENSOR_TYPE.PROBE) {
        if (!sensor.capacity && sensor.capacity !== 0) {
          sensorErrors.capacity = createFieldErrorMessage(
            "capacity",
            "required"
          );
        }

        if (!sensor.fillLimit && sensor.fillLimit !== 0) {
          sensorErrors.fillLimit = createFieldErrorMessage(
            "fillLimit",
            "required"
          );
        }

        if (!sensor.threshold && sensor.threshold !== 0) {
          sensorErrors.threshold = createFieldErrorMessage(
            "threshold",
            "required"
          );
        }
      }

      if (Object.keys(sensorErrors).length > 0) {
        if (!errors.sensors) {
          errors.sensors = [];
        }

        errors.sensors[idx] = sensorErrors;
      }
    });

    if (device.type === DEVICE_TYPE.IOT) {
      if (!values.iotSendTime1) {
        errors.iotSendTime1 = createFieldErrorMessage(
          "iotSendTime1",
          "required"
        );
      }

      if (values.iotType !== IOT_TYPE.STD) {
        if (!values.iotV230FailText.match("[A-Za-z0-9]*")) {
          errors.iotV230FailText = createFieldErrorMessage(
            "iotV230FailText",
            "invalid"
          );
        }

        if (!values.iotAlarm1Text.match("[A-Za-z0-9]*")) {
          errors.iotAlarm1Text = createFieldErrorMessage(
            "iotAlarm1Text",
            "invalid"
          );
        }

        if (!values.iotAlarm2Text.match("[A-Za-z0-9]*")) {
          errors.iotAlarm2Text = createFieldErrorMessage(
            "iotAlarm2Text",
            "invalid"
          );
        }

        if (values.iotTempMin < IOT_MIN_TEMP) {
          errors.iotTempMin = createFieldErrorMessage("iotTempMin", "invalid");
        }

        if (values.iotTempMax > IOT_MAX_TEMP) {
          errors.iotTempMax = createFieldErrorMessage("iotTempMax", "invalid");
        }
      }
    }

    return errors;
  };

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const { sensors, ...params } = values;

    params.sensors = sensors.map(sensor => {
      // Unset sensor type, sequence and id for API call
      const { id, sequence, type, ...sensorParam } = sensor;

      if (device.type === DEVICE_TYPE.LX_IOT) {
        sensorParam.fillHeight = sensor.fillHeightMm;
      }

      return sensorParam;
    });

    if (params.simCreditorId === SIM_CREDITOR_NONE) {
      params.simCreditorId = null;
    }

    if (params.timeInterval) {
      params.timeInterval = valueAndTimeUnitToSeconds(
        params.timeInterval.value,
        params.timeInterval.unit
      );
    }

    if (params.thresholdInterval) {
      params.thresholdInterval = valueAndTimeUnitToSeconds(
        params.thresholdInterval.value,
        params.thresholdInterval.unit
      );
    }

    if (params.iotSendTime1) {
      params.iotSendTime1 = format(new Date(params.iotSendTime1), "HH:mm");
    }

    if (params.iotSendTime2) {
      params.iotSendTime2 = format(new Date(params.iotSendTime2), "HH:mm");
    }

    api
      .put(`/v2/devices/${device.id}/configuration`, params)
      .then(response => {
        enqueueSnackbar("devices.settings.configuration.responses.updated", {
          variant: "success"
        });

        const sentSms = parseInt(response.headers["x-sms-sent"], 10);

        if (sentSms > 0) {
          enqueueSnackbar("devices.settings.configuration.responses.smsSent", {
            variant: "success"
          });
        }

        onClose(true);
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  const sensors = device.sensors.map(sensor => {
    let value = {
      id: sensor.id,
      sequence: sensor.sequence,
      type: sensor.type,
      name: sensor.name,
      isConnected: sensor.isConnected,
      subType: sensor.subType,
      unit: sensor.unit
    };

    if (sensor.type === SENSOR_TYPE.PROBE) {
      value = {
        ...value,
        contentType: sensor.contentType,
        fillLimit: sensor.fillLimit,
        capacity: sensor.capacity,
        threshold: sensor.threshold
      };

      if (sensor.temperatureLimitType !== "none") {
        value.temperatureLimitValue = sensor.temperatureLimitValue;
      }

      if (device.capabilities === DEVICE_CAPABILITIES.AMPERE) {
        value.fillHeight = sensor.fillHeight;
        value.contentDensity = sensor.contentDensity;
        value.geometry = sensor.geometry;
        value.groundClearance = sensor.groundClearance || 0;
      }

      if (device.type === DEVICE_TYPE.LX_IOT) {
        value.fillHeightMm = sensor.fillHeight;
        value.contentDensity = sensor.contentDensity;
        value.geometry = sensor.geometry;
        value.measuringRange = sensor.measuringRange;
      }

      if (device.type === DEVICE_TYPE.IOT) {
        value.measuringRange = sensor.measuringRange;
      }
    }

    return value;
  });

  let values = {
    sensors,
    thresholdSensor: device.thresholdSensor || "first",
    hardwareVersion: device.hardwareVersion
  };

  // Values if SMS or IoT
  if (
    device.type === DEVICE_TYPE.SMS ||
    device.type === DEVICE_TYPE.IOT ||
    device.type === DEVICE_TYPE.LX_IOT
  ) {
    // Values for SMS, IoT and LX-IOT
    values = {
      ...values,
      valueInterval: device.valueInterval
    };

    if (device.timeInterval === null) {
      values.timeInterval = {
        value: 0,
        unit: TIME_UNITS.HOUR
      };
    } else {
      values.timeInterval = inMostSuitableTimeUnit(device.timeInterval, [
        TIME_UNITS.HOUR,
        TIME_UNITS.DAY
      ]);
    }

    if (device.thresholdInterval === null) {
      values.thresholdInterval = {
        value: 0,
        unit: TIME_UNITS.HOUR
      };
    } else {
      values.thresholdInterval = inMostSuitableTimeUnit(
        device.thresholdInterval,
        [TIME_UNITS.HOUR, TIME_UNITS.DAY]
      );
    }

    // Values for SMS only
    if (device.type === DEVICE_TYPE.SMS) {
      values.smsHeader = device.smsHeader || "";
      values.simType = device.simType || "";
      values.simCreditorId = device.simCreditorId || SIM_CREDITOR_NONE;
      values.forceSms = false;

      if (device.smsGatewayType === DEVICE_SMS_GATEWAY_TYPE.LIONEL) {
        values.cellNumber = device.ident ? device.ident : "";
        values.simProvider = device.simProvider ? device.simProvider : "";
        values.inboundGatewayId = device.inboundGatewayId;
        values.outboundGatewayId = device.outboundGatewayId;
      }

      if (device.smsGatewayType === DEVICE_SMS_GATEWAY_TYPE.ONCE) {
        values.iccid = device.ident ? device.ident : "";
      }

      values.watchdogEnabled = device.watchdogEnabled;
    }

    // Values for IoT only
    if (device.type === DEVICE_TYPE.IOT) {
      values.thingName = device.ident ? device.ident : "";
      values.iotAlarm1Text = device.iotAlarm1Text ?? "";
      values.iotAlarm1Type = device.iotAlarm1Type;
      values.iotAlarm2Text = device.iotAlarm2Text ?? "";
      values.iotAlarm2Type = device.iotAlarm2Type;
      values.iotFtpUpdateEnabled = device.iotFtpUpdateEnabled;
      values.iotGpsInterval = device.iotGpsInterval;
      values.iotSendInterval = device.iotSendInterval;
      values.iotSendTime1 = device.iotSendTime1;
      values.iotSendTime2 = device.iotSendTime2;
      values.iotTempMax = device.iotTempMax;
      values.iotTempMin = device.iotTempMin;
      values.iotType = device.iotType;
      values.iotV230FailEnabled = device.iotV230FailEnabled;
      values.iotV230FailText = device.iotV230FailText ?? "";
      if (device.iotType !== IOT_TYPE.STD) {
        values.iotVersion = device.iotVersion;
      }
    }

    // Values for LX-IoT only
    if (device.type === DEVICE_TYPE.LX_IOT) {
      values.fillingRecognitionInterval = device.fillingRecognitionInterval;
    }
  }

  // Values if NET
  if (device.type === DEVICE_TYPE.NET) {
    values = {
      ...values,
      host: device.host || "",
      port: device.port || 0
    };
  }

  return (
    <ConfigurationForm
      capabilities={device.capabilities}
      values={values}
      onClose={onClose}
      showSmsGatewaySelect={smsGateways.length > 1}
      onSubmit={handleSubmit}
      validator={validate}
    />
  );
};

export default ConfigurationContainer;
