import React, { useMemo } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { parse } from "date-fns";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { FORMAT_DATETIME } from "../../../utils/formatting";
import useFormat from "../../../hooks/useFormat";
import { DEVICE_TYPE } from "../../../domain/device";

const Chart = React.memo(
  ({
    data,
    onHideSensorData,
    grantHideRights = false,
    thresholdLineValue,
    timePeriodOptions,
    initialTimePeriod,
    shouldShowTemperature
  }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { formatDate, formatNumber, formatUnit } = useFormat();

    const plotLines = [];

    if (thresholdLineValue) {
      plotLines.push({
        color: theme.palette.error.main,
        dashStyle: "longdash",
        value: thresholdLineValue,
        zIndex: 1000,
        width: 1
      });
    }

    Highcharts.setOptions({
      lang: {
        rangeSelectorFrom: t("highcharts.rangeSelector.from"),
        rangeSelectorTo: t("highcharts.rangeSelector.to"),
        loading: t("highcharts.loading"),
        zoom: t("highcharts.zoom"),
        shortMonths: [
          t("date.months.short.1"),
          t("date.months.short.2"),
          t("date.months.short.3"),
          t("date.months.short.4"),
          t("date.months.short.5"),
          t("date.months.short.6"),
          t("date.months.short.7"),
          t("date.months.short.8"),
          t("date.months.short.9"),
          t("date.months.short.10"),
          t("date.months.short.11"),
          t("date.months.short.12")
        ]
      }
    });

    const options = useMemo(() => {
      const { transformedData, hasTemperatureData } = data;
      const ret = {
        chart: {
          zoomType: "x",
          animation: false
        },
        navigator: {
          adaptToUpdatedData: false,
          series: {
            type: "line"
          },
          xAxis: {
            max: new Date().getTime()
          }
        },
        rangeSelector: {
          enabled: true,
          inputDateFormat: t("highcharts.rangeSelector.dateFormat"),
          inputEnabled: true,
          buttons: timePeriodOptions,
          selected: initialTimePeriod
        },
        credits: {
          enabled: false
        },
        title: {
          text: ""
        },
        plotOptions: {
          series: {
            turboThreshold: 0,
            dataGrouping: {
              enabled: false
            },
            showInNavigator: true,
            states: {
              inactive: {
                opacity: 0.5
              }
            },
            point: {
              events: {
                click: event => {
                  if (!grantHideRights) {
                    return;
                  }
                  const { point } = event;

                  onHideSensorData({
                    id: point.id,
                    sensorName: point.series.userOptions.label,
                    measuredAt: point.category
                  });
                }
              }
            }
          }
        },
        xAxis: {
          type: "datetime",
          ordinal: false,
          max: new Date().getTime()
        },
        yAxis: [
          {
            min: 0,
            max: 100,
            tickInterval: 20,
            showLastLabel: true,
            endOnTick: true,
            labels: {
              y: 5,
              format: "{value}%"
            },
            opposite: false,
            plotLines
          }
        ],
        tooltip: {
          borderColor: "#666",
          shared: true,
          useHTML: true,
          style: {
            padding: "1px"
          },
          formatter() {
            var s = '<table style="margin: 0; padding: 0; border: none">';
            s += "<thead>";
            s += "<tr>";
            s +=
              '<th width="150px" colspan="2" style="text-align: center; padding: 0.2rem 0.3rem; font-size: 0.8rem; color: #2c3a48; background-color: #fff">';
            s += formatDate(parse(this.x, "T", new Date()), FORMAT_DATETIME);
            s += "</th>";
            s += "</tr>";
            s += "</thead>";

            s += "<tbody>";

            this.points.forEach(point => {
              if (point.point.v !== null) {
                s += "<tr>";
                s +=
                  '<td style="padding: 0.2rem 0.3rem; font-size: 0.8rem; color: ' +
                  point.series.color +
                  '">' +
                  point.series.userOptions.label +
                  ": </td>";
                s +=
                  '<td style="padding: 0.2rem 0.3rem; font-size: 0.8rem; text-align: right"><b>' +
                  formatNumber(point.point.v) +
                  (point.series.userOptions.unit
                    ? " " + formatUnit(point.series.userOptions.unit)
                    : "") +
                  "</b></td>";
                s += "</tr>";
              }
            });

            s += "</tbody>";
            s += "</table>";

            return s;
          }
        },
        scrollbar: {
          barBackgroundColor: theme.palette.secondary.main,
          barBorderRadius: 3,
          barBorderWidth: 0,
          buttonBackgroundColor: theme.palette.secondary.main,
          buttonBorderWidth: 0,
          buttonBorderRadius: 3,
          buttonArrowColor: theme.palette.common.white,
          trackBackgroundColor: "none",
          rifleColor: theme.palette.common.white,
          trackBorderWidth: 1,
          trackBorderRadius: 2,
          trackBorderColor: theme.palette.grey[400],
          liveRedraw: false
        },
        series:
          shouldShowTemperature === true
            ? transformedData
            : transformedData.filter(
                series =>
                  typeof series.label === "string" &&
                  series.label !== "Temperature"
              )
      };

      ret.yAxis.push({
        min: -20,
        max: 80,
        tickInterval: 10,
        showLastLabel: true,
        endOnTick: true,
        lineWidth: 0.1,
        opposite: true, // Right y-axis for temperature values
        labels: {
          format: "{value}°C",
          formatter: function() {
            if (!hasTemperatureData || !shouldShowTemperature) return "";
            const temperatureData = transformedData.findLast(
              d => d.deviceType !== undefined
            );
            if (temperatureData.deviceType === DEVICE_TYPE.IOT) {
              let color = theme.custom.colors.temperature.line;
              if (this.value >= temperatureData.maxTemp) {
                color = theme.custom.colors.temperature.hot;
              } else if (this.value <= temperatureData.minTemp) {
                color = theme.custom.colors.temperature.cold;
              } else {
                color = theme.custom.colors.temperature.normal;
              }
              return `<span style="color: ${color}; font-weight: bold;">${this.value}°C</span>`;
            }
            return this.value + "°C";
          },
          useHTML: true
        }
      });
      return ret;
    }, [
      data,
      formatDate,
      formatNumber,
      formatUnit,
      grantHideRights,
      onHideSensorData,
      timePeriodOptions,
      initialTimePeriod,
      theme,
      t,
      plotLines
    ]);

    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType="stockChart"
          options={options}
        />
      </>
    );
  }
);

export default Chart;
